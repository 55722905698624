import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../Typography/Typography'
import cl from './BackButton.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Tooltip from '../tooltip/Tooltip';
import { TABLET_WIDTH, useWindowWidth } from '../../hooks/useWindowWidth';

function BackButton({ title, nav, callback, ...props }) {
	const navigate = useNavigate()
	const path = useLocation().pathname

	const textRef = useRef(null);
	const hiddenTextRef = useRef(null);
	const [isOverflow, setIsOverflow] = useState(false);
	const winWidth = useWindowWidth()

	const onClick = () => {
		if (nav) {
			navigate(nav)
		} else if (callback) {
			callback()
		} else {
			//navigate(-1)
			let lastIndex = path.lastIndexOf("/");
			let result = path.substring(0, lastIndex)
			navigate(result)
		}
	}

	useEffect(() => {
		const checkOverflow = () => {
			if (textRef.current && hiddenTextRef.current) {
				const isOverflowing = hiddenTextRef.current.offsetWidth > textRef.current.offsetWidth;
				setIsOverflow(isOverflowing);
			}
		};

		if(winWidth > TABLET_WIDTH) {
			checkOverflow();
		} else {
			setIsOverflow(false)
		}

		window.addEventListener('resize', checkOverflow);
		return () => window.removeEventListener('resize', checkOverflow);
	}, [title, winWidth]);

	return (
		<div className={classNames(cl.button, props?.className)} style={{ ...props?.style }} onClick={props?.onClick || onClick}>
			<i className='icon-arrow-left' />
			<div data-tip data-for='header-name' ref={textRef} className={cl.buttonText__container}>
				<Header>{title}</Header>
				<div ref={hiddenTextRef} className={cl.hidden__text}>{title}</div>
			</div>
			{isOverflow && <Tooltip id='header-name' >{title}</Tooltip>}
		</div>
	)
}

export default BackButton