import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'

import Checkbox from '../../../UI/checkbox/Checkbox'
import { Caption, Subheader } from '../../../UI/Typography/Typography'
import OptionsHorizontal from '../../../UI/optionsHorizontal/OptionsHorizontal'
import PlanChips from '../../../UI/planChips/PlanChips'

import cl from './GridLibItem.module.css'

import posterSk from '../../../assets/skeleton/poster.png'
import feeder from '../../../assets/vr/101.jpg'


function GridVRLibItem({ data, handleCheck, checked, setSelectAction, iRole, hardPoster, actions, subscrPlan, verification, ...props }) {
	const { category, fullname, company, name, description, plan, poster, duration, id, kiosk } = data

	const winWidth = useWindowWidth()
	const navigate = useNavigate()

	const [preview, setPrev] = useState(posterSk)

	const handleCheckItem = () => {
		handleCheck(id)
	}

	const handleLink = () => {
		navigate(`${id}`)
		//if (winWidth > TABLET_WIDTH) {
		//	navigate(`${id}`)
		//} else if (data.check !== false) {
		//	if(!verification) return
		//	handleCheckItem()
		//} else {
		//	toast.warn('Paid subscription required', {
		//		toastId: 'paid-subscription-required',
		//	})
		//}
	}

	const handleVRAssign = () => {
		handleCheckItem()
		setSelectAction('assignSettings', id)
	}

	const checkImageExists = async (url) => {
		try {
			const response = await fetch(url, {
				method: 'HEAD'
			});
			if (response.ok) {
				return url;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	};

	const getPoster = async () => {
		if (id == 101) {
			
			setPrev(feeder);
			return
		} else if (!poster) {
			setPrev(posterSk)
			return
		}
		const imageUrl = `${process.env.REACT_APP_MAIN_API_URL}/poster/${poster}`;
		const imageExists = await checkImageExists(imageUrl);
		if (imageExists) {
			setPrev(imageExists);
		} else {
			setPrev(posterSk);
		}
	};

	useEffect(() => {
		getPoster()
	}, [])

	return (
		<div className={classNames(cl.card, cl.card__vr)} style={{ backgroundColor: checked && '#F2F4F8' }}>

			<div className={cl.poster} onClick={handleLink}>
				<img src={preview} alt='poster' />
				{kiosk && <i className='icon-VR_kiosk_marker'></i>}
				{duration && <span className={cl.duration}>{duration}</span>}
				{winWidth < TABLET_WIDTH && checked && <i className={classNames(cl.check__icon)}>{checked}</i>}
				{/*{((subscrPlan && subscrPlan === 'Free') || (subscrPlan !== 'Free' && plan === 'Exclusive') || (plan === 'Flex')) && <span className={cl.plan}><PlanChips plan={plan} /></span>}*/}
				{(( plan === 'Exclusive') 
				|| (subscrPlan === 'Flex' && plan === 'Standard') 
				|| (!data?.check)  
				|| (subscrPlan === 'Free')) && <span className={cl.plan}><PlanChips plan={plan} /></span>}
			</div>
			<div className={cl.info}>
				<Link className={cl.header} to={`${id}`}>
					{category && <Caption>{category}</Caption>}
					<Subheader>{name}</Subheader>
				</Link>
				{winWidth > TABLET_WIDTH && <div className={cl.info__actions}>
					<div className={cl.checkbox}>
						<Checkbox checked={checked} onChange={handleCheckItem} disabled={data?.check === false || !verification || iRole === 'ENHANCED'} />
					</div>
					<div className={cl.menu}>
						<OptionsHorizontal
							vertical
							id={id}
							data={id}
							setSelectAction={setSelectAction}
							actions={actions}
							width={200}
							disabled={data?.check === false || !verification || iRole === 'ENHANCED'}
						/>
					</div>
				</div>}
			</div>
		</div>
	)
}

export default GridVRLibItem