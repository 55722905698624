import { useEffect, useRef } from "react";


const StickyElement = ({ top = 0, position = 'sticky', children }) => {
	const stickyRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					// Элемент стал "липким"
					//
				} else {
					// Элемент больше не "липкий"
					//
				}
			},
			{ threshold: 0.5 } // Порог видимости элемента
		);

		if (stickyRef.current) {
			observer.observe(stickyRef.current);
		}

		return () => {
			if (stickyRef.current) {
				observer.unobserve(stickyRef.current);
			}
		};
	}, []);

	return (
		<div ref={stickyRef} style={{ position, top, zIndex: 9 }}>
			{children}
		</div>
	);
};

export default StickyElement;