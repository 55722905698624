import classNames from 'classnames'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'

import Checkbox from '../../../UI/checkbox/Checkbox'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from './RoomItem.module.css'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getClassrooms } from '../../../redux/slices/ClassroomsSlice'
import ClassroomStatus from '../../../UI/classroomStatus/ClassroomStatus'

function RoomItem({ data, handleCheck, checked, checkedArr, setSelectAction, ...props }) {
	const { id, roomName, creator, date, users, status } = data
	const comID = useSelector((state) => state.userInfo.info?.company)
	const winWidth = useWindowWidth()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleCheckItem = () => {
		handleCheck(id, checkedArr)
	}

	const onEnter = async (e) => {
		e.preventDefault()
		const res = await dispatch(getClassrooms(comID))
		if (res.payload.data) {
			const room = res.payload.data.find(r => r.id === id)
			if (room.status === 'live' || room.status === 'active') {
				toast.warn('You cannot join an ongoing session. Wait for the end.')
			} else {
				navigate(`${id}`)
			}
		} else {
			toast.error('Something went wrong. Try again later.')
		}

	}

	const statusComponent = <>
		{status === 'active' ?
			<>
				<span style={{ background: 'var(--success-color)' }}></span>
				<Paragraph>Online</Paragraph>
			</>
			: status === 'live'
				? <>
					<i className='icon-start_session' style={{ color: 'var(--error-color)' }} />
					{/*<span style={{ background: 'var(--error-color)' }}></span>*/}
					<Paragraph style={{ color: 'var(--error-color)' }} >Live</Paragraph></>
				: <>
					<span></span>
					<Paragraph>Offline</Paragraph> </>
		}
	</>


	const card = (
		<div className={cl.card} style={{ backgroundColor: checked && '#F2F4F8' }}>
			<div className={cl.column__name}>
				<div className={cl.checkbox}>
					<Checkbox checked={checked} onChange={handleCheckItem} />
				</div>
				<Paragraph onClick={onEnter}>{roomName}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__curator)}>
				<Paragraph>{creator}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__date)}>
				<Paragraph>{date && moment(date).format('ll')}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__users)}>
				<Paragraph>{users}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__status)}>
								<ClassroomStatus status={status} />
			</div>
		</div>
	)

	const mobileCard = (
		<div className={cl.card__mobile} style={{ backgroundColor: checked && '#F2F4F8' }} onClick={handleCheckItem}>
			{checked && <div className={cl.checked}>
				<i className='icon-check' />
			</div>}
			<div className={cl.info}>
				<div className={cl.name}>
				<Paragraph onClick={onEnter}>{roomName}</Paragraph>
				</div>
				<div className={cl.status}>
					{statusComponent}
				</div>
			</div>
		</div>
	)

	return (
		winWidth > TABLET_WIDTH ? card : mobileCard
	)
}

export default RoomItem