import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth';

import Checkbox from '../../../UI/checkbox/Checkbox'
import { Paragraph } from './../../../UI/Typography/Typography';
import BatteryIcon from '../../../UI/batteryIcon/BatteryIcon';

import cl from './DeviceItem.module.css'

function DeviceItem({ data, handleCheck, checked, setSelectAction, ...props }) {
	const { name, id, model, date, freeMemory, userId, latestTimeEnd, userName, active, battery, kiosk } = data

	const winWidth = useWindowWidth()

	const handleCheckItem = () => {
		handleCheck(id)
	}

	const card = (
		<div className={cl.card} style={{ backgroundColor: checked && '#F2F4F8' }}>
			<div className={cl.column__name}>
				<div className={cl.checkbox}>
					<Checkbox checked={checked} onChange={handleCheckItem} />
				</div>
				{kiosk && <i className='icon-VR_kiosk_on' />}
				<Paragraph>
					<Link to={`${id}`}>{name}</Link>
					{/*{name}*/}
				</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__model)}>
				<Paragraph>{model}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__user)}>
				<Paragraph>{userName ? userName : 'Unassigned'}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__space)}>
				<Paragraph>{freeMemory ? freeMemory + ' GB' : 'n/a'}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__active)}>
				{latestTimeEnd === 'On'
					? <span>On</span>
					: <Paragraph>{latestTimeEnd  && moment(latestTimeEnd).format('ll')}</Paragraph>
				}
			</div>
			<div className={classNames(cl.column, cl.column__battery)}>
				{battery && <BatteryIcon battery={battery} />}
				<Paragraph>{battery ? `${battery}%` : 'n/a'}</Paragraph>
			</div>
			{/*<div className={classNames(cl.column, cl.column__actions)} onClick={(e) => e.stopPropagation()}>
				<OptionsHorizontal id={id} data={data} setSelectAction={setSelectAction} actions={actions} />
			</div>*/}
		</div>
	)

	const mobileCard = (
		<div className={cl.card__mobile} style={{ backgroundColor: checked && '#F2F4F8' }} onClick={handleCheckItem}>
			{checked && <div className={cl.checked}>
				<i className='icon-check' />
			</div>}
			<div className={cl.info}>
				<div className={cl.name}>
					{kiosk && <i className='icon-VR_kiosk_on' />}
					<Link to={`${id}`} >{name}</Link>
					{/*{name}*/}
				</div>
				<Paragraph>{userName ? userName : 'Unassigned'}</Paragraph>
			</div>
			<div className={cl.active}>
				{latestTimeEnd === 'On'
					? <span>On</span>
					: <Paragraph>{latestTimeEnd && moment(latestTimeEnd).format('ll')}</Paragraph>
				}
			</div>
			{/*<div className={cl.actions} onClick={(e) => e.stopPropagation()}>
				<OptionsHorizontal id={id} data={data} setSelectAction={setSelectAction} actions={actions} />
			</div>*/}
		</div>
	)

	return (
		winWidth > TABLET_WIDTH ? card : mobileCard
	)
}

export default DeviceItem