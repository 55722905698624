import classNames from 'classnames'
import cl from './UserAvatarIcon.module.css'

function UserAvatarIcon({link = '', ...props}) {
	return (
		<div className={classNames(cl.avatar, cl.avatar__wrap)}>
			{link
				? <img src={process.env.REACT_APP_MAIN_API_URL + `/avatar/${link}`} alt='avatar' />
				: <div className={classNames(cl.avatar__icon, 'icon-User')}></div>
			}
		</div>
	)
}

export default UserAvatarIcon