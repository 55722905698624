import React, { useMemo } from 'react'

import cl from './RandomAvatar.module.css'

const colors = ['#9DD1F1', '#EDE580', '#CFCFEA', '#C7F0BD', '#B3CDD1', '#FFE0B5']
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

function RandomAvatar({ size = 40, children, ...props }) {

	const getColorCode = () => {
		const index = alphabet.indexOf(children[0].toUpperCase())
		if (index <= 6) {
			return colors[0]
		} else if (index <= 12) {
			return colors[1]
		} else if (index <= 18) {
			return colors[2]
		} else if (index <= 24) {
			return colors[3]
		} else if (index <= 30) {
			return colors[4]
		}
		return colors[5]
	}

	const colorCode = useMemo(() => getColorCode(), [children])

	return (
		<div className={cl.avatar} style={{ width: size, height: size, backgroundColor: colorCode, ...props.style }}>
			{children}
		</div>
	)
}

export default RandomAvatar