import { Paragraph } from "../Typography/Typography"

import cl from './ClassroomStatus.module.css'

function ClassroomStatus({ status }) {
	return (
		<div className={cl.column__status}>
{			status === 'active' ?
			<>
				<span style={{ background: 'var(--success-color)' }}></span>
				<Paragraph>Online</Paragraph>
			</>
			: status === 'live'
			? <>
				<i className='icon-start_session' style={{ color: 'var(--error-color)' }} />
				{/*<span style={{ background: 'var(--error-color)' }}></span>*/}
				<Paragraph style={{ color: 'var(--error-color)' }} >Live</Paragraph></>
			: <>
				<span></span>
				<Paragraph>Offline</Paragraph> </>}
		</div>
	)
}

export default ClassroomStatus