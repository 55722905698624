import { useContext} from 'react'
import { uid } from 'uid'
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { vidDownloadingContext } from '../../pages/Home'
import { TABLET_WIDTH, useWindowWidth } from './../../hooks/useWindowWidth';

import DeviceItem from './tableItem/DeviceItem'
import RoomItem from './tableItem/RoomItem';
import TeamItem from './tableItem/TeamItem'
import UserItem from './tableItem/UserItem'
import RoomUserItem from './tableItem/RoomUserItem'
import VideoModalItem from './tableItem/VideoModaltem'
import TrainingStatisticItem from './tableItem/TrainingStatisticItem';
import UsersStatisticItem from './tableItem/UsersStatisticItem';
import GridVideoLibItem from './tableItem/GridVideoLibItem';
import GridVRLibItem from './tableItem/GridVRLibItem';
import TrainingSessionStatisticItem from './tableItem/TrainingSessionStatisticItem';
import TrainingSessionStatisticPersonalItem from './tableItem/TrainingSessionStatisticPersonalItem';

import SpinnerBlock from './../../UI/spinner/SpinnerBlock';
import Tooltip from '../../UI/tooltip/Tooltip'
import { Paragraph } from '../../UI/Typography/Typography'
import EmptyTable from '../../UI/emptyTable/EmptyTable';

import cl from './Table.module.css'

const columns = [
	{ 'id': uid(8), 'header': 'Name', 'key': 'email', 'style': { flexBasis: '35%', paddingLeft: 56 }, 'sort': true },
	{ 'id': uid(8), 'header': 'Role', 'key': 'role', 'style': { flexBasis: '13%' }, 'sort': true, 'tooltip': 'Role of the user' },
	{ 'id': uid(8), 'header': 'Teams', 'key': 'teams', 'style': { flexBasis: '13%' }, 'sort': false, 'tooltip': 'Teams of the user' },
	{ 'id': uid(8), 'header': 'Lessons', 'key': 'lessons', 'style': { flexBasis: '13%' }, 'sort': false, 'tooltip': 'Lessons of the user' },
	{ 'id': uid(8), 'header': 'Last activity', 'key': 'lastActivity', 'style': { flexBasis: '20%' }, 'sort': true, 'tooltip': 'The last time a user logged in' },
]

const columnsInvite = [
	{ 'id': uid(8), 'header': 'Email', 'key': 'email', 'style': { flexBasis: '35%', paddingLeft: 56 }, 'sort': true },
	{ 'id': uid(8), 'header': 'Role', 'key': 'role', 'style': { flexBasis: '30%' }, 'sort': true, 'tooltip': 'Role of the user' },
	{ 'id': uid(8), 'header': 'Invite date', 'key': 'date', 'style': { flexBasis: '30%' }, 'sort': true, 'tooltip': 'Date of user invitation' },
]

const columnsTeams = [
	{ 'id': uid(8), 'header': 'Team name', 'key': 'groupName', 'style': { flexBasis: '50%', paddingLeft: 56 }, 'sort': true },
	{ 'id': uid(8), 'header': 'Users', 'key': 'Users', 'style': { flexBasis: '20%' }, 'sort': true, 'tooltip': 'The number of users in the group' },
	{ 'id': uid(8), 'header': 'Lessons', 'key': 'lessons', 'style': { flexBasis: '20%' }, 'sort': true, 'tooltip': 'Number of lessons available' },
]

const columnsDevices = [
	{ 'id': uid(8), 'header': 'Device name', 'key': 'name', 'style': { flexBasis: '25%', paddingLeft: 56 }, 'sort': true },
	{ 'id': uid(8), 'header': 'Model', 'key': 'model', 'style': { flexBasis: '10%' }, 'sort': false, 'tooltip': 'Device model' },
	{ 'id': uid(8), 'header': 'Current user', 'key': 'user', 'style': { flexBasis: '20%' }, 'sort': false },
	{ 'id': uid(8), 'header': 'Free space', 'key': 'space', 'style': { flexBasis: '15%' }, 'sort': false },
	{ 'id': uid(8), 'header': 'Last activity', 'key': 'active', 'style': { flexBasis: '15%' }, 'sort': false },
	{ 'id': uid(8), 'header': 'Battery', 'key': 'battery', 'style': { flexBasis: '15%' }, 'sort': false },
]

const columnsRooms = [
	{ 'id': uid(8), 'header': 'Classroom name', 'key': 'roomName', 'style': { flexBasis: '35%', paddingLeft: 56 }, 'sort': true },
	{ 'id': uid(8), 'header': 'Curator', 'key': 'admin', 'style': { flexBasis: '25%' }, 'sort': false, 'tooltip': 'Curator' },
	{ 'id': uid(8), 'header': 'Date created', 'key': 'date', 'style': { flexBasis: '15%' }, 'sort': false, 'tooltip': 'Date created' },
	{ 'id': uid(8), 'header': 'Connected users', 'key': 'users', 'style': { flexBasis: '15%' }, 'sort': false, 'tooltip': 'Connected users' },
	{ 'id': uid(8), 'header': 'Status', 'key': 'status', 'style': { flexBasis: '10%' }, 'sort': false, 'tooltip': 'Status' },
]

const columnsRoomUsers = [
	{ 'id': uid(8), 'header': '#', 'key': 'number', 'style': { flexBasis: '10%', paddingLeft: 24 }, 'sort': false },
	{ 'id': uid(8), 'header': 'Connected users', 'key': 'name', 'style': { flexBasis: '30%' }, 'sort': true, 'tooltip': 'Users connected now' },
	{ 'id': uid(8), 'header': 'Device name', 'key': 'device', 'style': { flexBasis: '30%' }, 'sort': false, 'tooltip': 'Used device' },
	{ 'id': uid(8), 'header': 'Video status', 'key': 'status', 'style': { flexBasis: '20%' }, 'sort': false, 'tooltip': 'Video status' },
	{ 'id': uid(8), 'header': '', 'key': 'actions', 'style': { flexBasis: '10%' }, 'sort': false },
]

const columnsTrainingsStatistic = [
	{ 'id': uid(8), 'header': 'VR Training', 'key': 'name', 'style': { flexBasis: '45%', paddingLeft: 24 }, 'sort': true },
	{ 'id': uid(8), 'header': 'Total sessions', 'key': 'sessions', 'style': { flexBasis: '15%' }, 'sort': false, 'tooltip': 'Total number of sessions for this training (completed, incomplete, repeated) since its inception.' },
	{ 'id': uid(8), 'header': 'Session time', 'key': 'time', 'style': { flexBasis: '20%' }, 'sort': false, 'tooltip': 'Average session duration for users in this VR training.' },
	{ 'id': uid(8), 'header': 'Completion', 'key': 'totalPercent', 'style': { flexBasis: '20%' }, 'sort': true, 'tooltip': 'Ratio of completed to incomplete VR training sessions.' },
	//{ 'id': uid(8), 'header': 'Users enrolled', 'key': 'users', 'style': { flexBasis: '15%' }, 'sort': false },
	//{ 'id': uid(8), 'header': 'On devices', 'key': 'devices', 'style': { flexBasis: '10%' }, 'sort': false },
]

const columnsVideoStatistic = [
	{ 'id': uid(8), 'header': '360 video', 'key': 'videoName', 'style': { flexBasis: '45%', paddingLeft: 24 }, 'sort': true },
	{ 'id': uid(8), 'header': 'Total views', 'key': 'sessions', 'style': { flexBasis: '15%' }, 'sort': false, 'tooltip': 'Total video views' },
	{ 'id': uid(8), 'header': 'Avg. view time', 'key': 'time', 'style': { flexBasis: '20%' }, 'sort': false, 'tooltip': 'Average viewing time' },
	{ 'id': uid(8), 'header': 'Completion', 'key': 'completionRate', 'style': { flexBasis: '20%' }, 'sort': true, 'tooltip': 'Completion rate' },
	//{ 'id': uid(8), 'header': 'Users enrolled', 'key': 'users', 'style': { flexBasis: '15%' }, 'sort': false },
	//{ 'id': uid(8), 'header': 'On devices', 'key': 'devices', 'style': { flexBasis: '10%' }, 'sort': false },
]

const columnsUsersStatistic = [
	{ 'id': uid(8), 'header': 'User', 'key': 'name', 'style': { flexBasis: '27%', paddingLeft: 24 }, 'sort': true },
	{ 'id': uid(8), 'header': 'VR Sessions', 'key': 'sessions', 'style': { flexBasis: '15%' }, 'sort': false, 'tooltip': 'Total number of VR training sessions (completed + incomplete) by the selected user.' },
	{ 'id': uid(8), 'header': 'Avg. session time', 'key': 'time', 'style': { flexBasis: '20%' }, 'sort': false},
	{ 'id': uid(8), 'header': 'Completion', 'key': 'sessionCompletedCount', 'style': { flexBasis: '23%' }, 'sort': true, 'tooltip': 'Ratio of completed to incomplete VR training sessions.'},
	{ 'id': uid(8), 'header': 'Last activities', 'key': 'date', 'style': { flexBasis: '15%' }, 'sort': false },
]

const columnsVrSessionsStatistic = [
	{ 'id': uid(8), 'header': 'Session ID', 'key': 'sessionId', 'style': { flexBasis: '13%', paddingLeft: 24 }, 'sort': false },
	{ 'id': uid(8), 'header': 'Name', 'key': 'userEmail', 'style': { flexBasis: '21%' }, 'sort': true },
	{ 'id': uid(8), 'header': 'Session time', 'key': 'duration', 'style': { flexBasis: '14%' }, 'sort': false },
	{ 'id': uid(8), 'header': 'Activity', 'key': 'activity', 'style': { flexBasis: '18%' }, 'sort': true, },
	{ 'id': uid(8), 'header': 'Score', 'key': 'rate', 'style': { flexBasis: '14%' }, 'sort': false },
	{ 'id': uid(8), 'header': 'Completion', 'key': 'totalPercent', 'style': { flexBasis: '10%' }, 'sort': true },
	{ 'id': uid(8), 'header': '', 'key': 'action', 'style': { flexBasis: '10%' }, 'sort': false },
]

const columnsVrSessionsStatisticPersonal = [
	{ 'id': uid(8), 'header': 'Session ID', 'key': 'sessionId', 'style': { flexBasis: '18%', paddingLeft: 24 }, 'sort': false },
	{ 'id': uid(8), 'header': 'Session time', 'key': 'duration', 'style': { flexBasis: '20%' }, 'sort': false },
	{ 'id': uid(8), 'header': 'Activity', 'key': 'activity', 'style': { flexBasis: '22%' }, 'sort': true, },
	{ 'id': uid(8), 'header': 'Score', 'key': 'rate', 'style': { flexBasis: '16%' }, 'sort': false },
	{ 'id': uid(8), 'header': 'Completion', 'key': 'totalPercent', 'style': { flexBasis: '14%' }, 'sort': true },
	{ 'id': uid(8), 'header': '', 'key': 'action', 'style': { flexBasis: '10%' }, 'sort': false },
]

function Table({ data, handleSort, sortBy, handleCheck, checked, type = 'users', loading, setSelectAction, actions, children, verification, ...props }) {
	const { downloading } = useContext(vidDownloadingContext)

	const iRole = useSelector((state) => state.userInfo.info?.role)
	const subscrPlan = useSelector(state => state.subscription.info?.plan)
	const winWidth = useWindowWidth()

	const checkChecked = (id) => {
		return checked.includes(id)
	}

	const checkCheckedNumber = (id) => {
		return checked.includes(id) ? checked.indexOf(id) + 1 : ''
	}

	const columnsToRender = () => {
		if (type === 'invite') {
			return columnsInvite
		} else if (type === 'teams') {
			return columnsTeams
		} else if (type === 'devices') {
			return columnsDevices
		} else if (type === 'users') {
			return columns
		} else if (type === 'rooms') {
			return columnsRooms
		} else if (type === 'roomUsers') {
			return columnsRoomUsers
		} else if (type === 'trainingsStatistic') {
			return columnsTrainingsStatistic
		} else if (type === 'videoStatistic') {
			return columnsVideoStatistic
		} else if (type === 'vrSessionsStatistic') {
			return columnsVrSessionsStatistic
		} else if (type === 'usersStatistic') {
			return columnsUsersStatistic
		}
		else if (type ==="personalVrSessionsStatistic"){
			return columnsVrSessionsStatisticPersonal
		}
	}

	return (
		<div className={cl.table}>
			{/*<StickyElement>
				{children}
			</StickyElement>*/}

			{(winWidth > TABLET_WIDTH && type !== 'trainings' && type !== 'trainingsLite' && type !== 'videos' && type !== 'videosLite' && type !== 'videoModalItem')
				&& <div className={cl.table__header}>
					{columnsToRender().map((column, index) => {
						return (
							<div key={column.key} style={column.style} className={cl.column}>
								<Paragraph>{column.header}</Paragraph>
								{column.sort && <div className={cl.sort__icon} onClick={() => handleSort(column.key)}>
									<i className={sortBy === column.key ? 'icon-sort-outline' : 'icon-sort'} />
								</div>}

								{column.tooltip && <div className={cl.tooltip__info}>
									<i data-tip data-for={column.id} className='icon-circle-info' />
									<Tooltip id={column.id}>{column.tooltip}</Tooltip>
								</div>}
							</div>
						)
					})}
				</div>
			}
			<div className={classNames(cl.table__body, (type === 'trainings' || type === 'trainingsLite' || type === 'videos' || type === 'videosLite') && data.length > 0 && cl.table__grid)}>
				{(data && data.length > 0) ? data.map((info, index) => {
					return (
						(type === 'trainings' && <GridVRLibItem iRole={iRole} setSelectAction={setSelectAction} data={info} key={info.id} checked={checkCheckedNumber(info.id)} handleCheck={handleCheck} actions={actions} subscrPlan={subscrPlan} verification={verification} {...props} />)
						|| (type === 'trainingsLite' && <GridVRLibItem iRole={iRole} setSelectAction={setSelectAction} data={info} key={info.id} checked={checkCheckedNumber(info.id)} handleCheck={handleCheck} actions={actions} verification={verification} {...props} />)
						|| (type === 'devices' && <DeviceItem iRole={iRole} setSelectAction={setSelectAction} data={info} key={info.id} checked={checkChecked(info.id)} handleCheck={handleCheck}  />)
						|| (type === 'videos' && <GridVideoLibItem iRole={iRole} setSelectAction={setSelectAction} type={type} handleCheck={handleCheck} key={info.id || info.groupName} checked={checkCheckedNumber(info.id || info.groupName)} info={info} downloading={downloading?.[info.id] || null} downloaded={props.downloadedVideos} actions={actions} verification={verification} {...props}  />)
						|| (type === 'videosLite' && <GridVideoLibItem iRole={iRole} setSelectAction={setSelectAction} type={type} handleCheck={handleCheck} key={info.id || info.groupName} checked={checkCheckedNumber(info.id || info.groupName)} info={info} downloading={downloading?.[info.id] || null} downloaded={props.downloadedVideos} actions={actions} lite verification={verification} {...props} />)
						|| (type === 'videoModalItem' && <VideoModalItem iRole={iRole} type={type} handleCheck={handleCheck} key={info.id || info.groupName} checked={checkChecked(info.id)} checkedArr={checked} info={info} verification={verification} {...props} />)
						|| (type === 'rooms' && <RoomItem iRole={iRole} handleCheck={handleCheck} key={info.id} checkedArr={checked} checked={checkChecked(info.id)} data={info} />)
						|| (type === 'teams' && <TeamItem iRole={iRole} handleCheck={handleCheck} key={info.groupName} checkedArr={checked} checked={checkChecked(info.groupName)} info={info} />)
						|| (type === 'roomUsers' && <RoomUserItem iRole={iRole} key={info.userId || info.id} data={info} setSelectAction={setSelectAction} number={index + 1} />)
						|| ((type === 'trainingsStatistic' || type === 'videoStatistic') && <TrainingStatisticItem iRole={iRole} key={info.name || info.videoName} data={info} type={type} />)
						|| (type === 'vrSessionsStatistic' && <TrainingSessionStatisticItem iRole={iRole} key={info.trainingSessionId} data={info} />)
						|| (type === 'personalVrSessionsStatistic' && <TrainingSessionStatisticPersonalItem iRole={iRole} key={info.trainingSessionId} data={info} />)
						|| (type === 'usersStatistic' && <UsersStatisticItem iRole={iRole} key={info.name || info.email} data={info} />)
						|| (<UserItem iRole={iRole} type={type} handleCheck={handleCheck} key={info.id} checkedArr={checked} checked={checkChecked(info.id)} info={info} />)
					)
				}) : loading ? null : <div className={cl.notfound}>
					<Paragraph> {
						type === 'teams'
							? <EmptyTable icon='teams' />
							: props.subtype === 'downloaded'
								? <EmptyTable icon='saved' />
								: (type === 'videos' || type === 'videosLite' || type === 'videoModalItem' || type === 'videoStatistic')
									? <EmptyTable icon='video' />
									: type === 'rooms'
										? <EmptyTable icon='classrooms' />
										: (type === 'trainings' || type === 'trainingsLite' )
											? <EmptyTable icon='training' />
											: type === 'devices'
												? <EmptyTable icon='devices' />
												: type === 'vrSessionsStatistic'
													? <EmptyTable icon='vrSessionsStatistic' />
													: type === 'roomUsers'
														? <EmptyTable icon='users' style={{position: 'static', transform: 'none'}} />
														: <EmptyTable icon='users' />} </Paragraph>
				</div>}
				{loading && <div className={cl.loading}>
					<SpinnerBlock height={350} />
				</div>}
			</div>
		</div>
	)
}




export default Table