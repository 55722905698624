import classNames from 'classnames'
import React from 'react'
import Input from '../input/Input'

import cl from './SearchInput.module.css'

function SearchInput({ placeholder, value, setValue, onChange, loading, label, disabled, ...props }) {

	const cleanInput = () => {
		setValue('')
	}

	return (
		<div className={cl.wrap}>
			<Input className={classNames(cl.search, 'icon-search')}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				loading={loading}
				label={label}
				disabled={disabled}
				{...props}
			/>
			{value && <i className='icon-close' onClick={cleanInput} />}
		</div>
	)
}

export default SearchInput