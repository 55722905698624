import React from 'react'

import ReactTooltip from "react-tooltip";

import cl from './MenuTooltip.module.css'

function MenuTooltip({ id, children }) {
	return (
		<ReactTooltip
			className={cl.tooltip}
			clickable={true}
			scrollHide
			id={id}
			place="bottom" 
			effect="solid" 
			padding='0px' 
			arrowColor='transparent' 
			backgroundColor='transparent'
			globalEventOff='click'
			 >
			{children}
		</ReactTooltip>
	)
}

export default MenuTooltip