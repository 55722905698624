import classNames from 'classnames'
import moment from 'moment'
import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from './TrainingStatisticItem.module.css'
import prettyMilliseconds from 'pretty-ms'


function UsersStatisticItem({ data, ...props }) {

	const { id, name, sessionCount, averageDuration, sessionCompleted, completedVRTraining, email, lastActivity } = data

	const winWidth = useWindowWidth()

	const card = (
		<div className={cl.card}>
			<div className={classNames(cl.column, cl.column__users_name)}>
				<Paragraph >
					{name || email}
				</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__users_total)}>
				<Paragraph>{sessionCount}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__users_time)}>
				<Paragraph>{(typeof (+averageDuration) === 'number' && +averageDuration > 0 && prettyMilliseconds(averageDuration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 })) || '0 m'}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__rate_users)}>
				<Paragraph>
					{sessionCompleted && Math.round(parseFloat(sessionCompleted))}%</Paragraph>
			</div>

			<div className={classNames(cl.column, cl.column__activ_users)}>
				{lastActivity && moment(lastActivity).format('MMM DD, YYYY')}
			</div>
		</div>
	)

	const mobileCard = (
		<div className={cl.card__mobile}>
			<div className={classNames(cl.column, cl.column__name)}>
				<Paragraph >
					{name}
				</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__total)}>
				<Paragraph>{sessionCount}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__time)}>
			<Paragraph>{(typeof (+averageDuration) === 'number' && +averageDuration > 0 && prettyMilliseconds(averageDuration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 })) || '0 m'}</Paragraph>
			</div>
		</div>
	)
	return (
		winWidth > TABLET_WIDTH ? card : mobileCard
	)
}

export default UsersStatisticItem