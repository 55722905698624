import React from 'react'
import MenuTooltip from '../menuTooltip/MenuTooltip'
import { Paragraph } from '../Typography/Typography'

import cl from './OptionsHorizontal.module.css'
import { useEffect } from 'react';
import  classNames  from 'classnames';

//const actions = [
//	{
//		name: 'Disconnect',
//		action: 'grantAccess'
//	}
//]

function OptionsHorizontal({ id, setSelectAction, vertical = false, data, actions, width = 184, disabled = false, ...props }) {

	//*add at parent component e.stopPropagation()
	
	const handleSelectAction = (action, data) => {
		setSelectAction(action, data)
	}


	return (
		<>
			<i data-tip data-for={id} data-event={disabled ? '' : 'click focus'} className={classNames(vertical ? 'icon-options-vertical' : 'icon-options-horizontal', cl.icon, disabled && cl.disabled)} />
			<MenuTooltip id={id}>
				<div className={cl.options} style={{width: width}}>
					{actions && actions.map((action, index) => (
						<div key={index} className={cl.option} onClick={() => handleSelectAction(action.action, data)}>
							{action.icon && <i className={action.icon} />}
							<Paragraph >{action.header}</Paragraph>
						</div>
					))}
				</div>
			</MenuTooltip>
			</>
	)
}

export default OptionsHorizontal