import classNames from 'classnames'

import RoleChips from '../../../UI/roleChips/RoleChips'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from './TableItem.module.css'

function UserItemPR({user, checked, type = 'users', ...props }) {
	if(!user) return null
	return (
		<div className={classNames(cl.row, checked && cl.checked)} {...props}>
			<div className={classNames(cl.column__name, type === 'invite' && cl.invite)}>
				<div className={classNames(cl.avatar, cl.avatar__wrap)}>
					{user.avatar
						? <img src={process.env.REACT_APP_MAIN_API_URL + `/avatar/${user.avatar}`} alt='avatar' />
						: <div className={classNames(cl.avatar__icon, 'icon-User')}></div>
					}
					{checked && <i className='icon-check'></i>}
				</div>

				<div className={cl.user}>
					<Paragraph>{user?.name || user?.groupName}</Paragraph>
					<Paragraph>{user.email}</Paragraph>
				</div>

			</div>
			<div className={classNames(cl.role, type === 'invite' && cl.invite)}>
				<RoleChips role={user.role} />
			</div>

		</div>
	)
}

export default UserItemPR