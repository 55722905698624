import React, { useMemo, useState } from 'react'

import Table from '../../../components/table/Table'
import TableControl from '../../../components/table/tableControl/TableControl'
import StickyElement from '../../../UI/sticky/StickyElement'

import cl from './UsersTable.module.css'

function UsersTable({ selectFilter, searchFilter, multipleFilter = [], data, checked, setChecked, setAllChecked, icons, setSelectAction, type, loading, verification, ...props }) {

	const [sortBy, setSortBy] = useState('email')
	const [sortDirection, setSortDirection] = useState('asc')

	const sortedUsers = useMemo(() => {
		if (!data) return null
		let sortedUsers = [...data]

		sortedUsers = sortedUsers.filter((item) => {
			if ((searchFilter && !item?.name?.toUpperCase().includes(searchFilter.toUpperCase()) && !item?.email?.toUpperCase().includes(searchFilter.toUpperCase())) || (selectFilter !== 'all' && item?.role?.toUpperCase() !== selectFilter)) {
				return false;
			}
			;
			if (multipleFilter.length > 0 && !multipleFilter.includes(item?.role.toUpperCase())) {
				return false;
			}

			if (selectFilter !== 'all' && item?.role?.toUpperCase() !== selectFilter) {
				return false;
			}

			return true;
		});

		sortedUsers.sort((a, b) => {
			if (a[sortBy] < b[sortBy]) {
				return sortDirection === 'asc' ? -1 : 1
			}
			if (a[sortBy] > b[sortBy]) {
				return sortDirection === 'asc' ? 1 : -1
			}
			return 0
		})
		//if (searchFilter || selectFilter) {
		//	return sortedUsers.filter((item) => item?.name?.toUpperCase().includes(searchFilter.toUpperCase()) || item?.email?.toUpperCase().includes(searchFilter.toUpperCase())
		//		?
		//		selectFilter === 'all' ? item : item?.role?.toUpperCase() === selectFilter : null)
		//}
		//if(selectFilter) {
		//	return sortedUsers.filter((item) => selectFilter === 'all' ? item : item.role.toUpperCase() === selectFilter)
		//}

		return sortedUsers
	}, [data, sortBy, sortDirection, selectFilter, searchFilter, multipleFilter])

	const handleSort = (key) => {
		if (sortBy === key) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
		} else {
			setSortBy(key)
			setSortDirection('asc')
		}
	}

	//const handleCheck = (id) => {
	//	setChecked(id)
	//}


	return (
		<div className={cl.container}>
			<StickyElement top={-17}>
				<TableControl
					checked={checked}
					setAllChecked={setAllChecked}
					icons={icons}
					setSelectAction={setSelectAction}
					data={data}
					verification={verification} />
			</StickyElement>
			<Table loading={loading} type={type} handleSort={handleSort} checked={checked} sortBy={sortBy} data={sortedUsers} handleCheck={setChecked} />
		</div>
	)
}

export default UsersTable