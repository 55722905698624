import classNames from 'classnames'

import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'
import OptionsHorizontal from '../../../UI/optionsHorizontal/OptionsHorizontal'
import { Caption, Paragraph } from '../../../UI/Typography/Typography'

import cl from './RoomUserItem.module.css'


function RoomUserItem({ data, actions, setSelectAction, number, ...props }) {
	const { userId, id, email, role, deviceName, isReady } = data
	;
	const winWidth = useWindowWidth()

	const card = (
		<div className={cl.card}>
			<div className={classNames(cl.column, cl.column__number)}>
				<Paragraph>
					{number}
				</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__name)}>
				<Paragraph>
					{email}
				</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__device)}>
				<Paragraph>{deviceName}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__status)}>
				{isReady ? <><i className='icon-offline_pin' />
					<Paragraph>Ready</Paragraph>
				</> :
					<><i className='icon-warning_circle' />
						<Paragraph>Suboptimal</Paragraph>
					</>
				}
			</div>
			<div className={classNames(cl.column, cl.column__actions)} onClick={(e) => e.stopPropagation()}>
				<OptionsHorizontal id={userId || id} data={data} setSelectAction={setSelectAction} actions={actions} />
			</div>
		</div>
	)

	const mobileCard = (
		<div className={cl.card__mobile}>
			<div className={cl.info}>
				<div className={cl.name}>
					<Paragraph>
						{email}
					</Paragraph>
				</div>
				<Caption>{deviceName}</Caption>
			</div>
			<div className={classNames(cl.column, cl.column__actions)} onClick={(e) => e.stopPropagation()}>
				<OptionsHorizontal id={userId || id} data={data} setSelectAction={setSelectAction} actions={actions} />
			</div>
		</div>
	)

	return (
		winWidth > TABLET_WIDTH ? card : mobileCard
	)
}

export default RoomUserItem