import classNames from 'classnames'
import React, { useEffect } from 'react'
import { uid } from 'uid'

import cl from './Switch.module.css'

function Switch({ checked, onChange, children, ...props }) {
	let id = uid(8) 
	return (
		<div className={cl.container}>
			<label className={cl.switch}>
				<input id={id} type="checkbox" checked={checked} onChange={onChange} {...props} />
				<span className={classNames(cl.slider, cl.round)}></span>
			</label>
			<label htmlFor={id}>{children}</label>
		</div>
	)
}

export default Switch