import React from 'react'
import RandomAvatar from '../randomAvatar/RandomAvatar'

import cl from './TeamNameColumn.module.css'
import { Paragraph } from '../Typography/Typography'
import { Link } from 'react-router-dom'

function TeamNameColumn({ info }) {
	return (
		<div className={cl.column}>
			<div className={cl.avatar__wrap}>
				{info?.shortName && <RandomAvatar>{info?.shortName}</RandomAvatar>}
			</div>

			<div className={cl.team__info}>
				<Link to={`${info?.groupName}`}>{info?.groupName}</Link>
			</div>
		</div>
	)
}

export default TeamNameColumn