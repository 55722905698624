import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ContentModal from '../../UI/modal/ContentModal'
import { Paragraph, TextError } from '../../UI/Typography/Typography'
import UserItem from './userItem/UserItem'

import cl from './AddToTeamModal.module.css'
import Input from '../../UI/input/Input'


function CreateTeam({ modalIsOpen, setModalIsOpen, checkedIds, setCheckedIds, onConfirm, onClose, loading, error, ...props }) {

	const users = useSelector(state => state.users.users)

	const [inputVal, setInputVal] = useState('')
	const [inpErr, setInpErr] = useState('')

	const onInputChange = (e) => {
		if (inpErr) setInpErr('')
		setInputVal(e.target.value)
	}

	const [checkedUsers, setCheckedUsers] = useState([])

	useEffect(() => {
		if (users && checkedIds.length > 0) {
			if (checkedIds[0].id) {
				setCheckedUsers(checkedIds)
			} else {
				setCheckedUsers(users.filter(user => checkedIds.includes(user.id)))
			}

		}
	}, [checkedIds, users])


	const onUserUncheck = (user) => {
		if (checkedIds[0].id) {
			setCheckedIds(checkedIds.filter(checkedId => checkedId.id !== user.id), 'active')
			setCheckedUsers(checkedUsers.filter(checkedUser => checkedUser.id !== user.id))
			return
		} else {
			const newChecked = checkedIds.filter(checkedId => checkedId !== user.id)
			setCheckedIds(newChecked, 'active')
			setCheckedUsers(checkedUsers.filter(checkedUser => checkedUser.id !== user.id))
		}
	}

	const onClean = () => {
		setCheckedUsers([])
	}

	const onCreateTeam = () => {
		if (inputVal.includes('/')) {
			setInpErr('Team name must not contain "/"')
			return
		}
		if (inputVal.length > 1) {
			const id = localStorage.getItem('id')
			let addedUsers
			if(checkedIds[0].id) {
				addedUsers = [...checkedIds.map(user => user.id), id]
			} else {
				addedUsers = [...checkedIds, id]
			}
			onConfirm(inputVal, addedUsers, onClean)
		} else {
			setInpErr('Team name must be more than 1 character')
		}
	}

	return (
		<ContentModal
			header='Create team'
			textButton='Create'
			modalIsOpen={modalIsOpen}
			setModalIsOpen={setModalIsOpen}
			onConfirm={onCreateTeam}
			closeOnConfirm={false}
			onClose={onClose}
			loading={loading}
			disabled={checkedUsers.length === 0 || loading}
		>
			<div className={cl.container}>
				<Input label='Team name' value={inputVal} onChange={onInputChange} />
				<div className={cl.scroll} style={{ marginTop: 6, marginRight: -24, height: 230 }}>
					{checkedUsers.length > 0
						? checkedUsers.map(user => (
							<UserItem user={user} onDelete={onUserUncheck} key={user.id} style={{ marginRight: 8, marginLeft: -15 }} />
						))
						: <Paragraph className={cl.noUsers}>No users added</Paragraph>}
				</div>
				{(error || inpErr) && <TextError style={{ textAlign: 'center' }}>{error || inpErr}</TextError>}
			</div>
		</ContentModal>
	)
}

export default CreateTeam