import classNames from 'classnames'
import Overlay from '../overlay/Overlay'
import { Header } from '../Typography/Typography'

import cl from './Sidebar.module.css'

function Sidebar({ sidebarIsOpen, setIsOpen, children, title, ...props }) {
	const sidebarClose = () => {
		setIsOpen(false)
	}
	return (
		<Overlay modalIsOpen={sidebarIsOpen} setIsOpen={setIsOpen} style={{ justifyContent: 'flex-end' }}>
			<div className={classNames(cl.sidebar, sidebarIsOpen && cl.active)} onClick={(e) => e.stopPropagation()}>
				<div className={cl.header}>
					<i className='icon-close' onClick={sidebarClose} />
					<Header>{title}</Header>
				</div>
				{children}
			</div>
		</Overlay>
	)
}

export default Sidebar