import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'

import Checkbox from '../../../UI/checkbox/Checkbox'
import Tooltip from '../../../UI/tooltip/Tooltip'
import { Caption, Paragraph, Subheader } from '../../../UI/Typography/Typography'

import cl from './GridLibItem.module.css'

import posterSk from '../../../assets/skeleton/poster.png'
import OptionsHorizontal from '../../../UI/optionsHorizontal/OptionsHorizontal'
import Spinner from '../../../UI/spinner/Spinner'

function GridVideoLibItem({ info, handleCheck, checked, setSelectAction, downloading, iRole, actions, lite = false, verification, ...props }) {
	const { videoTheme, videoName, videoDescription, preview, duration, id, status = '', companyId } = info
	const downloadedVideos = useSelector(state => state.downloadedVideos.videos)
	const winWidth = useWindowWidth()
	const navigate = useNavigate()

	const [poster, setPoster] = useState(posterSk)

	const handleCheckItem = () => {
		if(!verification) return;
		
		if (downloading) {
			toast.warn('Video is loading')
			return
		}
		handleCheck(id)
	}

	const handlePlay = () => {
		if (winWidth < TABLET_WIDTH) {
			navigate(`/home/videos/${id}`)
			//handleCheckItem()
		} else {
			setSelectAction('playVideo', info)
		}

	}

	const handleVideoAssign = () => {
		handleCheckItem()
		setSelectAction('videoAssign', id)
	}

	const handleVideoDelete = () => {
		handleCheckItem()
		setSelectAction('oneVideoDelete', id)
	}

	const handleAddToCR = () => {
		handleCheckItem()
		setSelectAction('addToCR', id)
	}

	const checkImageExists = async (url) => {
		try {
			const response = await fetch(url, {
				method: 'HEAD'
			});
			if (response.ok) {
				return url;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	};

	const getPoster = async () => {
		const imageUrl = `${process.env.REACT_APP_MAIN_API_URL}/${preview}`;
		const imageExists = await checkImageExists(imageUrl);
		if (imageExists) {
			setPoster(imageExists);
		}
	};

	useEffect(() => {
		getPoster()
	}, [])


	const downloaded = Boolean(downloadedVideos?.find((item) => item.id === id)) || false



	return (
		<div className={classNames(cl.card, cl.card__video)} style={{ backgroundColor: checked && '#F2F4F8' }}>

			<div className={classNames(cl.poster, cl.poster__video, downloading && cl.downloading, checked && cl.checked)} onClick={handlePlay}>
				<img src={poster} alt='poster' />
				{duration && winWidth > TABLET_WIDTH && <span className={cl.duration}>{duration?.slice(3, 8)}</span>}
				{winWidth < TABLET_WIDTH && checked && <i className={classNames(cl.check__icon)}>{checked}</i>}
				{downloading
					? <i className={classNames('icon-file-download', cl.icon__download)}><span>{downloading}%</span></i>
					: (winWidth > TABLET_WIDTH) && !lite ? <i className={classNames(cl.icon__play)} /> : null
				}

				{status === 'converting' ? <div className={cl.status}><Spinner width={20} white /><Paragraph>Converting...</Paragraph></div>
					: status === 'inqueue' ? <div className={cl.status}><Spinner width={20} white /><Paragraph>In the queue</Paragraph></div>
						: null
				}
				{downloaded && <div className={cl.poster__downloaded}>
					<i />
				</div>}

			</div>
			<div className={cl.info}>
				<Link className={cl.header} to={`/home/videos/${id}`}>
					<Caption>{videoTheme || videoName}</Caption>
					<Subheader>{videoName}</Subheader>
				</Link>
				{winWidth > TABLET_WIDTH && <div className={cl.info__actions}>
					<div className={cl.checkbox}>
						<Checkbox checked={checked} onChange={handleCheckItem} disabled={!verification}/>
					</div>
					<div className={cl.menu}>
						<OptionsHorizontal
							vertical
							id={id}
							data={id}
							setSelectAction={setSelectAction}
							actions={actions}
							width={200}
							disabled={!verification}
						/>
					</div>
				</div>}
			</div>
		</div >
	)
}

export default GridVideoLibItem