import { TabPanel, TabView } from 'primereact/tabview'

import  './TabsPrime.css'

function TabsPrime({ links, tabs, settedTab, activeTab = 0, onSetTab, ...props }) {

	const onTabChange = (e) => {
		onSetTab && onSetTab(e.index)
	}

	return (
		<TabView onBeforeTabChange={onTabChange} activeIndex={activeTab}  {...props} >
			{
				tabs.map((tab, index) => (
					<TabPanel header={links[index]} key={index}>
						{tab}
					</TabPanel>
				))
			}
		</TabView>
	)
}

export default TabsPrime