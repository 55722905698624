import classNames from 'classnames'

import RandomAvatar from '../../../UI/randomAvatar/RandomAvatar'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from './TableItem.module.css'

function TeamItemPR({ info, checked, ...props }) {
	
	return (
		<div className={classNames(cl.row, checked && cl.checked)} {...props}>
			<div className={classNames(cl.column__name, cl.teams)}>
				<div className={cl.avatar__wrap}>
					{info?.shortName && <RandomAvatar>{info?.shortName}</RandomAvatar>}
					{ checked && <i className='icon-check'></i>}
				</div>

				<div className={classNames(cl.info, cl.team__info)}>
					<Paragraph>{info?.groupName}</Paragraph>
				</div>

			</div>
		</div>
	)
}

export default TeamItemPR