import classNames from "classnames";
import prettyMilliseconds from "pretty-ms";
import { TABLET_WIDTH, useWindowWidth } from "../../../hooks/useWindowWidth";
import { Paragraph } from "../../../UI/Typography/Typography";

import cl from "./TrainingStatisticItem.module.css";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "../../../UI/Button/Button";
import UserAvatarIcon from "../../../UI/userAvatarIcon/UserAvatarIcon";
import { useState } from "react";
import { uid } from "uid";
import Tooltip from "../../../UI/tooltip/Tooltip";



function TrainingSessionStatisticItem({ data, ...props }) {
	const {
		trainingSessionId,
		userId,
		userEmail,
		userName,
		avatar,
		sessionTime,
		totalPercent,
		rating,
		scenarioName,
		activity,
		finalScore,
		maxScore
	} = data;
	const winWidth = useWindowWidth();
	const navigate = useNavigate();

	const onClickButton = () => {
		navigate(`${trainingSessionId}`);
	};
	const [id, setId] = useState(uid())
	const card = (
		<div className={cl.card}>
			<div className={classNames(cl.column, cl.column__id)}>
				<Paragraph>{trainingSessionId}</Paragraph>
				<span className={cl.tooltip__info}>
					<i data-tip data-for={id} className="icon-circle-info" />
					<Tooltip id={id} place="top">{scenarioName}</Tooltip>
				</span>

			</div>

			<Link
				to={`/home/settings/${userId}`}
				className={classNames(cl.column, cl.column__user)}
			>
				<div className={cl.column__user_avatar}><UserAvatarIcon link={avatar} /></div>

				<div className={cl.column__user_text}>
					<Paragraph style={{ color: "var(--textdark-color", fontWeight: 500 }}>
						{userName && userName}
					</Paragraph>
					<Paragraph>{userEmail && userEmail}</Paragraph>
				</div>
			</Link>
			<div className={classNames(cl.column, cl.column__duration)}>
				{sessionTime ? (
					<Paragraph>
						{prettyMilliseconds(+sessionTime, {
							unitCount: 2,
							keepDecimalsOnWholeSeconds: false,
							secondsDecimalDigits: 0,
						})}
					</Paragraph>
				) : "n/a"}
			</div>
			<div className={classNames(cl.column, cl.column__activity)}>
				{(activity && moment(activity).format("MMM DD, YYYY")) || "n/a"}
			</div>
			<div className={classNames(cl.column, cl.column__score)}>
				{rating === 'n/a' ? "Not available" : rating}
				{/* Not available */}
			</div>
			<div className={classNames(cl.column, cl.column__complition)}>
				{maxScore === 'n/a' ? '-' : Math.round(totalPercent) + '%'}
				{/* Not available */}
			</div>
			<div className={classNames(cl.column, cl.column__details)}>
				<Button
					disabled={rating === "n/a" || rating === '0.0' }
					linkbtn
					icon="right"
					right
					onClick={onClickButton}
				>
					Details
				</Button>
			</div>
		</div>
	);

	return winWidth > TABLET_WIDTH ? card : null;
}

export default TrainingSessionStatisticItem;
