import classNames from 'classnames'
import { Link } from 'react-router-dom'
import prettyMilliseconds from 'pretty-ms';

import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from './TrainingStatisticItem.module.css'

function TrainingStatisticItem({ data, type, ...props }) {
	const { id, name, videoName, count, sessionCount, avgDuration, averageView, totalPercent, trainingAvailableToUsers, videoAvailableToUsers, devices } = data

	const winWidth = useWindowWidth()

	const card = (
		<div className={cl.card}>
			<div className={classNames(cl.column, cl.column__name)}>
				{type === 'videoStatistic'
					? <Paragraph to={id} >
						{name || videoName}
					</Paragraph>
					:
					<Link to={id} >
						{name || videoName}
					</Link>
				}
			</div>
			<div className={classNames(cl.column, cl.column__total)}>
				<Paragraph>{sessionCount || count || '0'}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__time)}>
				<Paragraph>{(typeof (+avgDuration || averageView) === 'number' && (+avgDuration || +averageView) > 0 && prettyMilliseconds(avgDuration || averageView, {unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0})) || (typeof (+avgDuration || averageView) === 'number' && +avgDuration || averageView > 0 && prettyMilliseconds(avgDuration || averageView, {unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0})) || '0'}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__rate)}>
				<Paragraph>{totalPercent && Math.round(parseFloat(totalPercent))}%</Paragraph>
			</div>
			{/*<div className={classNames(cl.column, cl.column__users)}>
				{trainingAvailableToUsers || videoAvailableToUsers}
			</div>*/}
			{/*<div className={classNames(cl.column, cl.column__devices)}>
				{devices}
			</div>*/}
		</div>
	)

	const mobileCard = (
		<div className={cl.card__mobile}>
			<div className={classNames(cl.column, cl.column__name)}>
				<Paragraph >
				{name || videoName}
				</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__total)}>
			<Paragraph>{sessionCount || count || '0'}</Paragraph>
			</div>
			<div className={classNames(cl.column, cl.column__time)}>
				<Paragraph>{(Number.isInteger(+avgDuration) && +avgDuration > 0 && prettyMilliseconds(avgDuration)) || (Number.isInteger(+averageView) && +averageView > 0 && prettyMilliseconds(averageView)) || '0'}</Paragraph>
			</div>
		</div>
	)
	return (
		winWidth > TABLET_WIDTH ? card : mobileCard
	)
}

export default TrainingStatisticItem