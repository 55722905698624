import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sortArray } from '../../utils/SortArrayByAnotherArr';

export const getCurrentUsers = createAsyncThunk(
	'socketRoomUsers/getCurrentUsers',
	async (data, { rejectWithValue }) => {
		const { socket, classId } = data
		try {
			socket.emit('getCurrentUsers', { classId })
			return 'joined'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const joinRoom = createAsyncThunk(
	'socketRoomUsers/joinRoom',
	async (data, { rejectWithValue }) => {
		const { socket, classId, email, userId, role } = data
		try {
			await socket.emit('joinRoom', {
				email,
				classId,
				userId,
				role,
				isReady: true,
				deviceName: 'default'
			})
			return 'joined'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const pushUserToCR = createAsyncThunk(
	'socketRoomUsers/addUserToCR',
	async (data, { rejectWithValue }) => {
		try {
			return data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteUserFromCR = createAsyncThunk(
	'socketRoomUsers/deleteUserFromCR',
	async (data, { rejectWithValue }) => {
		try {
			return data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const kickUser = createAsyncThunk(
	'socketRoomUsers/kickUser',
	async (data, { rejectWithValue }) => {
		const { socket, clientId } = data
		try {
			 await socket.emit('kickUser', {
				clientId,
			})
			return 'user kicked'
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)


const socketRoomUsersAdapter = createEntityAdapter({
	selectId: (user) => 'eyJhbGciOiJIUzI1NcCI6IkpXVCJ9.eyJsb2dpbiI6InN0b3Jtb2ZlQGdtYWlsLmNvbSIsImlhdCI6MTY3NDA0NDUwMCwiZXhwIjoxNjc0MzAzNzAwfQ.ijXRAR60ziSok51Wi20GnkMqJckbtl648YjW_7T_LQk'
})

const socketRoomUsersSlice = createSlice({
	name: 'teams',
	initialState: socketRoomUsersAdapter.getInitialState({
		loading: false,
		error: null,
		users: []
	}),
	reducers: {
		setUsers: (state, action) => {
			console.log(action.payload, 'setUsers')
			state.users = action.payload
			//state.users = action.payload.filter(user => user.deviceName !== 'default')
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCurrentUsers.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getCurrentUsers.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(getCurrentUsers.rejected, (state, action) => {
				state.loading = false
			})
			.addCase(joinRoom.pending, (state, action) => {
				state.loading = true
			})
			.addCase(joinRoom.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(joinRoom.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(pushUserToCR.pending, (state, action) => {
				state.loading = true
			})
			.addCase(pushUserToCR.fulfilled, (state, action) => {
				state.loading = false
				state.users.push(action.payload)
			})
			.addCase(pushUserToCR.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(deleteUserFromCR.pending, (state, action) => {
				state.loading = true
			})
			.addCase(deleteUserFromCR.fulfilled, (state, action) => {
				state.loading = false
				state.users = state.users.filter(user => user.id !== action.payload)
			})
			.addCase(deleteUserFromCR.rejected, (state, action) => {
				state.loading = false
			})

			.addCase(kickUser.pending, (state, action) => {
				state.loading = true
			})
			.addCase(kickUser.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(kickUser.rejected, (state, action) => {
				state.loading = false
			})
	}
})

export const {
	setUsers
} = socketRoomUsersSlice.actions
export default socketRoomUsersSlice.reducer
