import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import classNames from 'classnames'

import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'

import Checkbox from '../../../UI/checkbox/Checkbox'
import RoleChips from '../../../UI/roleChips/RoleChips'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from './TableItem.module.css'

function UserItem({ info, handleCheck, checked, checkedArr, invite, type = 'users', ...props }) {
	const winWidth = useWindowWidth()
	const navigate = useNavigate()

	const onNameClick = () => {
		if (winWidth < TABLET_WIDTH) {
			handleCheck(info.id, checkedArr)
		} else {
			navigate(`/home/settings/${info.id}`)
		}
	}

	return (
		<div className={classNames(cl.row, checked && cl.checked)}>
			<div className={classNames(cl.column__name, type === 'invite' && cl.invite)}>
				{winWidth > TABLET_WIDTH &&
					<div className={cl.checkbox}>
						<Checkbox checked={checked} onChange={() => handleCheck(info.id, checkedArr)} />
					</div>
				}
				<div className={classNames(cl.avatar, cl.avatar__wrap)}>
					{info.avatar
						? <img src={process.env.REACT_APP_MAIN_API_URL + `/avatar/${info.avatar}`} alt='avatar' />
						: <div className={classNames(cl.avatar__icon, 'icon-User')}></div>
					}
					{winWidth < TABLET_WIDTH && checked && <i className='icon-check'></i>}
				</div>

				<div className={cl.info} onClick={onNameClick}>
					<Paragraph>{info?.name || info?.groupName}</Paragraph>
					<Paragraph>{info.email}</Paragraph>
				</div>

			</div>
			<div className={classNames(cl.role, type === 'invite' && cl.invite)}>
				<RoleChips role={info.role} />
			</div>
			{winWidth > TABLET_WIDTH && type === 'users' &&
				<>
					<div className={classNames(cl.column__text, cl.teams)}>
						<Paragraph>{info.groups?.length}</Paragraph>
					</div>
					<div className={classNames(cl.column__text, cl.lessons)}>
						<Paragraph>{info.lessons?.length}0</Paragraph>
					</div>
					<div className={classNames(cl.column__text, cl.activity)}>
						<Paragraph>{info.lastActivity && moment(info.lastActivity).format('ll')}</Paragraph>
					</div>
				</>
			}
			{winWidth > TABLET_WIDTH && type === 'invite' &&
				<div className={classNames(cl.column__text, cl.invite)}>
					<Paragraph>{info.lastActivity && moment(info.lastActivity).format('ll')}</Paragraph>
				</div>
			}
		</div>
	)
}

export default UserItem