import Checkbox from '../../../UI/checkbox/Checkbox'
import { Paragraph, Subheader } from '../../../UI/Typography/Typography'

import classNames from 'classnames'
import cl from './LibItem.module.css'

import posterSk from '../../../assets/skeleton/poster.png'

function VideoModalItem({ info, handleCheck, checkedArr, checked, ...props }) {
	const { videoTheme, videoName, preview, duration, id } = info

	const handleCheckItem = () => {
		handleCheck(id, checkedArr)
	}

	const poster = preview ? `${process.env.REACT_APP_MAIN_API_URL}/${preview}` : posterSk

	return (
		<div className={classNames(cl.card, cl.small)}>
			<div className={classNames(cl.checkbox, cl.small)}>
				<Checkbox checked={checked} onChange={handleCheckItem} />
			</div>
			<div className={classNames(cl.poster, cl.poster__video, cl.small)} onClick={handleCheckItem}>
				<img src={poster} alt='poster' />
				<span>{duration?.slice(3, 8)}</span>
			</div>
			<div className={classNames(cl.info, cl.small)}>
				{videoTheme && <Paragraph>{videoTheme}</Paragraph>}
				<div className={classNames(cl.header, cl.small)} >
					<Subheader>{videoName}</Subheader>
				</div>
			</div>
		</div>
	)
}

export default VideoModalItem