import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Paragraph } from '../Typography/Typography'

import cl from './UserColumn.module.css'

import user from '../../assets/skeleton/user_skeleton.png'

function UserColumn({ rowData }) {
	const navigate = useNavigate()
	const onPreventCheck = (e) => {
		e.preventDefault()
		navigate(`/home/settings/${rowData.id}`)
	}

	const [avatar, setAvatar] = useState(user)

	const checkImageExists = async (url) => {
		try {
			const response = await fetch(url, {
				method: 'HEAD'
			});
			if (response.ok) {
				return url;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	};

	const getAvatar = async () => {
		if (!rowData.avatar) {
			setAvatar(user)
			return
		}
		const imageUrl = `${process.env.REACT_APP_MAIN_API_URL}/avatar/${rowData.avatar}`;
		const imageExists = await checkImageExists(imageUrl);
		if (imageExists) {
			setAvatar(imageExists);
		} else {
			setAvatar(user);
		}
	};

	useEffect(() => {
		getAvatar()
	}, [])

	return (
		<div className={cl.row}>
			<img src={avatar} alt={rowData.name || rowData.userName} style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10 }} />
			<div className={cl.name} onClick={onPreventCheck}>
				<Paragraph>{rowData.name || rowData.userName}</Paragraph>
				<a className='link'>{rowData.email || rowData.userEmail}</a>
			</div>
		</div>
	);
}

export default UserColumn