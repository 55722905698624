import cl from './ScrollBlock.module.css'
import  classNames  from 'classnames';

function ScrollBlock({ children, height=50, maxHeight = null, ...props}) {
  return (
	 <div {...props} className={classNames(cl.scroll, props.className)} style={{height: height, maxHeight: maxHeight}} >
		{children}
	 </div>
  )
}

export default ScrollBlock