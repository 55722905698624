import React from 'react'
import { Caption } from '../Typography/Typography'

import cl from './RoleChips.module.css'

function RoleChips({ role = 'Owner', ...props }) {

	const styleChips = role.toLowerCase() === 'admin'
		? { backgroundColor: '#FEF4D1' }
		: role.toLowerCase() === 'enhanced'
			? { backgroundColor: '#DAFFF6' } : null

	const styleText = role.toLowerCase() === 'admin'
		? { color: '#DEB82E' }
		: role.toLowerCase() === 'enhanced'
			? { color: '#4BC0A3' } : null

	return (
		<div style={{...styleChips, ...props.style}} className={cl.chips}>
			<Caption style={styleText}>{role}</Caption>
		</div>
	)
}

export default RoleChips