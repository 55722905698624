import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { uid } from "uid";
import { newPrepareDataForChartByDays} from "../../utils/DataForStatistic";
import { PersonalVRStatisticService } from './../../services/PersonalVRStatisticServices';

export const getTrainingSessions365Personal  = createAsyncThunk(
	'personalVRStatistic/getTrainingSessions365Personal',
	async ({comID, userId}, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await PersonalVRStatisticService.getTrainingSessions365Personal(token, comID, userId);
			return response.data.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getTrainingTablePersonal = createAsyncThunk(
	'personalVRStatistic/getTrainingTablePersonal',
	async ({comID, userId}, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await PersonalVRStatisticService.getTrainingTablePersonal(token, comID, userId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

const personalVRStatisticAdapter = createEntityAdapter({
	selectId: (statistic) => uid(8)
})

const personalVRStatisticSlice = createSlice({
	name: 'personalVRStatistic',
	initialState: personalVRStatisticAdapter.getInitialState({
		loading: true,
		error: null,
		trainingSessionsTable: null,
		trainingSessions: {
			title: 'VR sessions',
			color: "#89A6FB",
			loading: true,
			error: null,
			365: null,
			90: null,
			30: null,
			7: null,
			ticks: null,
			ticksMob: null
		},
	}),
	extraReducers: (builder) => {
		builder


			.addCase(getTrainingTablePersonal.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getTrainingTablePersonal.fulfilled, (state, action) => {
				state.loading = false;
				state.trainingSessionsTable = action.payload.data;
			})
			.addCase(getTrainingTablePersonal.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getTrainingSessions365Personal.pending, (state, action) => {
				state.trainingSessions.loading = true;
			})
			.addCase(getTrainingSessions365Personal.fulfilled, (state, action) => {
				state.trainingSessions.loading = false;
				let obj = newPrepareDataForChartByDays(action.payload)
				if(!obj) return
				state.trainingSessions[365] = obj[365]
				state.trainingSessions[90] = obj[90]
				state.trainingSessions[30] = obj[30]
				state.trainingSessions[7] = obj[7]
				state.trainingSessions.error = null
				state.trainingSessions.ticks = obj.ticks
				state.trainingSessions.ticksMob = obj.ticksMob
			})
			.addCase(getTrainingSessions365Personal.rejected, (state, action) => {
				state.trainingSessions.loading = false
				state.trainingSessions.error = action.payload
			})
	}
})

export default personalVRStatisticSlice.reducer;