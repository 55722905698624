import classNames from 'classnames'
import React from 'react'

import cl from './BatteryIcon.module.css'

function BatteryIcon({ battery, ...props }) {

	const powerLevel = battery >= 51 ? cl.hight : battery > 20 ? cl.medium : cl.low

	const heightScale = battery/100*90

	return (
		<i className={classNames('icon-battery_b', cl.icon, powerLevel)} {...props}>
			<span className={classNames(cl.battery, powerLevel)} style={{height: heightScale+'%'}}></span>
		</i>
	)
}

export default BatteryIcon