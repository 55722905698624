import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'
import Checkbox from '../../../UI/checkbox/Checkbox'
import RandomAvatar from '../../../UI/randomAvatar/RandomAvatar'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from './TableItem.module.css'

function TeamItem({ info, handleCheck, checked, checkedArr, invite, ...props }) {
	const winWidth = useWindowWidth()
	return (
		<div className={classNames(cl.row, checked && cl.checked)}>
			<div className={classNames(cl.column__name, cl.teams)}>
				{winWidth > TABLET_WIDTH &&
					<div className={cl.checkbox}>
						<Checkbox checked={checked} onChange={() => handleCheck(info.groupName, checkedArr)} />
					</div>
				}
				<div className={cl.avatar__wrap} onClick={() => handleCheck(info.groupName, checkedArr)}>
					{info?.shortName && <RandomAvatar>{info?.shortName}</RandomAvatar>}
					{winWidth < TABLET_WIDTH && checked && <i className='icon-check'></i>}
				</div>

				<div className={classNames(cl.info, cl.team__info)}>
					<Link to={`${info?.groupName}`}><Paragraph>{info?.groupName}</Paragraph></Link>
				</div>

			</div>

			{winWidth > TABLET_WIDTH  &&
				<>
					<div className={classNames(cl.column__text, cl.users)}>
						<Paragraph>{info?.Users?.length}</Paragraph>
					</div>
					<div className={classNames(cl.column__text, cl.team__lessons)}>
						<Paragraph>{info?.lessons?.length}</Paragraph>
					</div>
				</>
			}
		</div>
	)
}

export default TeamItem